import Vue from 'vue';
import editor from './App.vue';

import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

Vue.config.productionTip = false

new Vue({
  render: h => h(editor),
}).$mount('#app')
